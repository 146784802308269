/*Screen width*/
.sub-campaign-allocation-header {
  width: 100%;
  background-color: #ffffff;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.sub-campaign-allocation-grid-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.allocation-row {
  background-color: #ffffff;
  margin-left: 0px !important;
  width: 100%;
  position: relative;
}
.allocation-row .allocation-btn {
  position: absolute;
  bottom: 1.2rem;
  right: 2rem;
  padding: 0 1.2rem;
  height: 2rem !important;
}
.allocation-row__download-btn {
  position: absolute;
  bottom: 2.2rem;
  left: 46rem;
  font-size: 1.2rem;
}

.actions-menu__list {
  background: #f1f5f6;
  padding: 0.3rem;
}
.actions-menu__icon {
  color: #ffffff;
  background: #2f477c;
  padding: 0.5rem;
  border-radius: 0.4rem;
}
.actions-menu__icon ::v-deep .v-icon,
.actions-menu__icon ::v-deep .icon-btn__text {
  color: #ffffff;
}
.actions-menu__icon ::v-deep .icon-btn__text {
  padding: 0 1rem 0 0;
  border-right: 0.1rem solid;
  margin-right: 1rem;
}
.actions-menu__icon ::v-deep .v-icon {
  padding-right: 0.3rem;
}
.actions-menu__icon:disabled {
  background: #ffffff;
  border: 0.1rem solid #d9d9d9;
  color: #2f477c;
}
.actions-menu__icon:disabled ::v-deep .v-icon,
.actions-menu__icon:disabled ::v-deep .icon-btn__text {
  color: #2f477c;
}
.actions-menu__icon:disabled ::v-deep .icon-btn__text {
  color: #d9d9d9;
  border-right: none;
}
.actions-menu.v-menu__content {
  border-radius: 0;
  margin-top: -0.5rem;
  padding: 0;
}
.actions-menu .v-list-item {
  border-top: 0.1rem solid #ffffff;
  border-bottom: 0.1rem solid #ffffff;
  padding: 0;
  min-height: 3.1rem;
}
.actions-menu .v-list-item:hover {
  background: #e1f1fb;
}
.actions-menu .v-list-item:first-of-type {
  border-top: none;
}
.actions-menu .v-list-item:last-of-type {
  border: none;
}
.actions-menu .v-list-item button,
.actions-menu .v-list-item ::v-deep button {
  width: 100%;
  height: 100%;
}
.actions-menu .v-list-item button:hover,
.actions-menu .v-list-item ::v-deep button:hover {
  background: none;
}
.actions-menu .v-list-item button:hover:before,
.actions-menu .v-list-item ::v-deep button:hover:before {
  opacity: 0;
}
.actions-menu .v-list-item button .v-btn__content,
.actions-menu .v-list-item ::v-deep button .v-btn__content {
  justify-content: flex-start;
  color: #39477b;
}

::v-deep .workflow-cell span {
  cursor: default;
}
::v-deep .checkbox-width .ag-cell-value {
  width: 1.6rem;
}
::v-deep .promotion-rag-colour {
  margin-top: 1rem !important;
}
::v-deep .is-child span {
  padding-bottom: 0.6rem;
  padding-left: 0.2rem;
}
::v-deep .is-child span:hover {
  cursor: pointer;
}
::v-deep .ag-filter-body-wrapper {
  min-width: 21rem;
}